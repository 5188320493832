import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  z-index: 2;

  @media (max-width: 575px) {
    margin-top: 3rem;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  &:after {
    content: '';
    position: absolute;
    left: 95%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    padding: 3rem 3rem 3rem 23rem;
  }

  @media (max-width: 991px) {
    padding: 2rem;
  }
`

const Content = styled(ParseContent)``

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 2;

  @media (min-width: 992px) {
    top: -50px;
    left: -350px;
  }

  @media (max-width: 991px) {
    bottom: -190px;
    right: -76px;
  }

  @media (max-width: 575px) {
    top: -140px;
    right: 40px;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 682px;
    height: 517px;
  }

  @media (max-width: 991px) {
    width: 425px;
    height: 330px;
  }

  @media (max-width: 575px) {
    width: 222px;
    height: 170px;
  }
`

interface FileProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_File
}

const File: React.FC<FileProps> = ({ fields }) => (
  <Section className="mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-end">
        <div className="position-relative col-lg-9">
          <Wrapper>
            <Content content={fields.description} />
            <ButtonTertiary
              to={fields.link?.url}
              target="_blank"
              className="mt-5"
            >
              {fields.link?.title}
            </ButtonTertiary>
            <ImageWrapper>
              <Image image={fields.image} alt="" />
            </ImageWrapper>
          </Wrapper>
        </div>
      </div>
    </div>
  </Section>
)

export default File
